<template>
 <div >
    <!--篩選區塊-->
    <el-row>
                                            <!--替換:過濾條件-->
      <!--
      <el-divider content-position="right"> {{$t("FilterConditions")}} </el-divider> -->

      <el-button
         style="float: left;"
         size="small"
         type="primary"
         icon="el-icon-tickets"
         v-if="(list!=undefined&&list!=null&&list.length>0)&&(listAll==undefined||listAll==null||listAll.length<=0)"
         @click="exportReport()"
       >{{$t('ExportReport')}}
       </el-button>

      <div style="float: left;">
      <!--匯出CSV按鈕:若回傳listall若無資料則匯出按鈕不顯示-->
      <download-excel  type="csv"
                       v-if="listAll!=undefined&&listAll!=null&&listAll.length>0">
           <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="exportToCSV" >.csv</el-button>
      </download-excel>         
      <!--
      <download-excel :data="listAll" :fields="json_fields" :name="filenameAll" type="csv"
                      v-if="listAll!=undefined&&listAll!=null&&listAll.length>0">
          <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="logOperate('report','/report/station','EXPORT','匯出充電站充電統計報表 csv')">{{$t('DownloadAllDataCsv')}}</el-button>
      </download-excel>-->
      <!--匯出CSV按鈕:若回傳listall若無資料則匯出按鈕不顯示-->      
      </div>

      <div style="float: left;">
      <!--listAll Export to XLS-->
      <excel-xlsx
        :data="listAll" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xls'" :sheet-name="'sheetname'"
        v-if="listAll!=undefined&&listAll!=null&&listAll.length>0"
        >
        <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block; margin-right:10px;" @click="logOperate('report','/report/station','EXPORT','匯出充電站充電統計報表  xls')">.xls</el-button>
      </excel-xlsx>
      <!--listAll Export to XLS-->
      </div>

      <div style="float: left;">
      <!--listAll Export to XLSX-->
      <excel-xlsx
        :data="listAll" :columns="xlsxColumns" :file-name="filenameAllXlsx" :file-type="'xlsx'" :sheet-name="'sheetname'"
        v-if="listAll!=undefined&&listAll!=null&&listAll.length>0"
        >
        <el-button type="success" size="mini" icon="el-icon-download" style="display: inline-block" @click="logOperate('report','/report/station','EXPORT','匯出充電站充電統計報表  xlsx')">.xlsx</el-button>
      </excel-xlsx>
      <!--listAll Export to XLSX-->
      </div>

      <div style="float: right;">                            <!--替換:充電站選擇-->
        <el-select v-model="listQuery.stationId" :placeholder="$t('ChargingStationS')+'('+$t('All')+')'"
           clearable size="small" class="filter-item-mid" width="140px">
           <!--
          <el-option v-for="item in stationOption" :key="item.stationId"
                    :label="item.stationName + ' (id:' + item.stationId + ')'" :value="item.stationId" />-->
          <el-option v-for="item in stationOption" :key="item.stationId"
                    :label="item.stationName" :value="item.stationId" />
        </el-select>

        <el-select v-model="listQuery.chargePointId" :placeholder="$t('ChargingPointS')+'('+$t('All')+')'"
           clearable size="small" class="filter-item-mid" width="90px">
           <!--
          <el-option v-for="item in chargeOption"  :key="item.pointId"
                    :label="item.pointName + ' (id:' + item.pointId + ')'" :value="item.pointId" />-->
          <el-option v-for="item in chargeOption"  :key="item.pointId"
                    :label="item.pointName" :value="item.pointId" />
        </el-select>

        <!--
        <el-input size="small" v-model="listQuery.staionId" placeholder="查詢" class="filter-item" clearable />      -->

        <!--
        <el-date-picker
            v-model="listQuery.updatedate"
            size="small"
            class="filter-item"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('Date')" >
        </el-date-picker>  -->
        <!--
         <el-date-picker
            v-model="dateRange"
            size="small"
            class="filter-item"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="listQuery.startdate"
            :end-placeholder="listQuery.enddate">  
        </el-date-picker> -->
        <el-date-picker
            v-model="dateStart"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.startdate">
        </el-date-picker>             
        <el-date-picker
            v-model="dateEnd"
            size="small"
            class="filter-item"            
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="listQuery.enddate">
        </el-date-picker>          
      </div>
    </el-row>

    <!-- table -->
    
    <!--
    <el-table
      size="mini"
      stripe border height="500px"
      v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
      :header-cell-style="{ background: '#fcd581', color: '#333' }"
    >-->
    <el-table
      size="mini"
      v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
    >    
      <!--<el-table-column align="center" prop="no" min-width="30px"        label="No." />  -->
      <!--
      <el-table-column align="center" prop="charge_point_id"
           :formatter="showPointName"  :label="$t('ChargingPoint')" sortable /> -->
      <el-table-column align="center" prop="stationname"     min-width="60px"  :label="$t('ChargingStation')" sortable /><!--替換:充電站-->           
      <el-table-column align="center" prop="name" :label="$t('ChargingPoint')" sortable /> <!--替換:充電樁-->
      <el-table-column align="center" prop="connector_id"         :label="$t('ChargingConnector')" sortable /> <!--替換:充電槍-->
      <el-table-column align="center" prop="state"  :label="$t('Status')" sortable />    <!--替換:狀態-->
      <el-table-column align="center" prop="voltage"              :label="$t('VoltageV')" sortable >  <!--替換:電壓(V)-->
        <template slot-scope="scope">
            <el-tag v-if="scope.row.voltage>='200'" type="warning" > {{scope.row.voltage}} </el-tag>
            <el-tag v-else-if="scope.row.voltage>='100'" type="success"> {{scope.row.voltage}} </el-tag>
            <el-tag v-else > {{scope.row.voltage}} </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="current_import"       :label="$t('CurrentA')"/>            <!--替換:電流(A)-->
      <el-table-column align="center" prop="power_active_import"  :label="$t('PowerW')" sortable/>     <!--替換:功率(W)-->
      <el-table-column align="center" prop="energy_active_import_register" :label="$t('MeterDegree')" sortable /><!--替換:電表(Wh)-->
      <el-table-column align="center" prop="energy_active_import_interval" :label="$t('IntervalDegree')" sortable /><!--替換:區間度數(Wh)-->
      <!--<el-table-column align="center" prop="current_offered"      :label="$t('Maximum')+$t('CurrentA')"/>替換:最大電流(A)-->
      <!-- 根據測試,不論飛宏或起而行充電樁都拿不到這個充電進度,故移除
      <el-table-column align="center" prop="state_of_charge"      :label="$t('ChargingProgress')" sortable> 替換:充電進度(%)
        <template slot-scope="scope">
            <el-tag v-if="scope.row.state_of_charge<='30'" type="warning" > {{scope.row.state_of_charge}}%</el-tag>
            <el-tag v-else-if="scope.row.state_of_charge<='70'" type="primary" > {{scope.row.state_of_charge}}% </el-tag>
            <el-tag v-else-if="scope.row.state_of_charge>'70'"  type="success" > {{scope.row.state_of_charge}}% </el-tag>
        </template>
      </el-table-column>
      -->
      <el-table-column width="145px" align="center" prop="update_time" :label="$t('Update')+$t('Date')" sortable :show-overflow-tooltip="true" min-width="100%"><!--替換:更新日期-->
      <template slot-scope="scope"> {{ parseDateTime(scope.row.update_time) }}</template>

      </el-table-column>
    </el-table>

    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { mixins } from '@/views/common/mixins.js';

/* 每隔10min刷新出jwt token*/
import {refreshToken,stopRefreshToken} from "@/utils/auth";


export default {
    name: 'Station',
    components: {
        Pagination,
    },
    mixins: [mixins],
    data() {
        return {
            dialogWidth: 0,
            total: 0,
            stationOption:undefined,
            chargeOption:undefined,
            list: [],
            listCsv: [],
            listAll: [],
            listLoading: false,
            listQuery: {
                //serviceId: this.$store.state.currentAccount.id,
                serviceId: this.$store.state.currentAccount.serviceInfo.id,
                stationId: undefined,
                chargePointId: undefined,
                startdate: this.$moment().subtract(90, "days").format("YYYY-MM-DD"),//90天前,
                enddate:   this.$moment().format("YYYY-MM-DD"),
                page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
                size:15,
                sort:"update_time,DESC",
            },
            paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
            dateRange: undefined,
            dateStart: undefined,
            dateEnd:   undefined,
            dialogSettingVisible: false,
            json_fields: {
              "Charge Point Id": "charge_point_id",    //CSV只允許英文
              "Connector Id"   : "connector_id",
              "Status"         : "state",
              "Voltage(V)"     : "voltage",
              "Current(A)"     : "current_import",
              "Power(W)"       : "power_active_import",
              "Meter(Wh)"      : "energy_active_import_register",
              "Interval(Wh)"   : "energy_active_import_interval",
              "Max Current(A)" : "current_offered",
              //"Charge State(%)": "state_of_charge", /* 根據測試,不論飛宏或起而行充電樁都拿不到這個值,故移除 */
              "Update Date"    : "update_time"
            },
            json_meta: [
              [ {
                  " key "   : " charset ",
                  " value " : " utf- 8 "
                } ]
            ],
            xlsxColumns : [
                {
                    label: this.$t('ChargingStation'),
                    field: "stationname",
                },              
                {
                    label: this.$t('ChargingPoint'),
                    field: "name",
                },
                {
                    label: this.$t('ChargingConnector'),
                    field: "connector_id",
                },
                {
                    label: this.$t('Status'),
                    field: "state",
                },
                {
                    label: this.$t('VoltageV'),
                    field: "voltage",
                },
                {
                    label: this.$t('CurrentA'),
                    field: "current_import",
                },
                {
                    label: this.$t('PowerW'),
                    field: "power_active_import",
                },
                {
                    label: this.$t('MeterDegree'),
                    field: "energy_active_import_register",
                },
                {
                    label: this.$t('IntervalDegree'),
                    field: "energy_active_import_interval",
                },
                /*
                {
                    label: this.$t('Maximum')+this.$t('CurrentA'),
                    field: "current_offered",
                },
                */
                /* 根據測試,不論飛宏或起而行充電樁都拿不到這個值,故移除
                {
                    label: this.$t('ChargingProgress'),
                    field: "state_of_charge",
                },
                */
                {
                    label: this.$t('Update')+this.$t('Date'),
                    field: "update_time",
                },
                ],
            /* 匯出檔名 */
            filenameCsv:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_ChargingReport" + ".xls",
            filenameAll:     this.$moment().format("YYYYMMDD-HHmmss").toString() + "_ChargingReportAll" + ".xls",
            filenameAllXlsx: this.$moment().format("YYYYMMDD-HHmmss").toString() + "_ChargingReportAll" ,
        };
    },
  //Init Vue->初始化props、data、methods後,則進入created階段進行資料拿取
  created() {
    //定時刷新
    //refreshToken();

    this.dialogWidth = this.setDialogWidth();
    this.getStationByServiceId();
    this.resetPage(this.getList);
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  beforeDestroy() {
    console.log("Call stopRefreshToken");
    //stopRefreshToken();
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    /* 偵測若語系切換,則進行本組件切出去在切回來,以達本組件語系包含子組件(含對話視窗)都刷新 */
    '$i18n.locale': function(val) {
      console.log('Role組件重載前')
      this.$router.push( { path:'/refresh',query:{ fromPath:this.$route.fullPath } })
      console.log('Role組件重載完成')
      },
    /* ----------------------------------------------------------------------------- */
    'listQuery.stationId': function (val) {
      this.listQuery.page = 0;
      this.listQuery.chargePointId = null;
      this.resetPage(this.getList);
      this.listAll= [];
      this.chargeOption=null;
      /* 若充電站有被選擇,則才會找該充電站所屬的充電樁,沒被選擇則stationId為0 */
      if( this.listQuery.stationId != null && this.listQuery.stationId != undefined && this.listQuery.stationId > 0 ) {
        console.log("GET:/api/v1/report/pointidListByserviceIdandstationId?serviceId="+this.listQuery.serviceId+"&stationId="+val);
        this.axios.get("/api/v1/report/pointidListByserviceIdandstationId?serviceId="+this.listQuery.serviceId+"&stationId="+val).then(
            res => {
            console.log("Response:pointidListByserviceIdandstationId [res.data]",res.data);
            this.chargeOption=res.data;
            }
            );
      }
     },
    'listQuery.chargePointId': function (val) {
      this.listQuery.page = 0;
      this.resetPage(this.getList);
      this.listAll= [];
     },
        /*
    'listQuery.updatedate': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    },
    }, */
    /*
    dateRange: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateRange[0]
        this.listQuery.enddate = this.dateRange[1]
      } else {
        this.listQuery.startdate = undefined
        this.listQuery.enddate = undefined
      }
      this.listQuery.page = 0;
      this.resetPage(this.getList)
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
      this.listAll= []
    },*/
    dateStart: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateStart
      } else {
        this.listQuery.startdate = undefined
      }
        this.listAll= [];
        this.listQuery.page = 0;
        this.resetPage(this.getList);      
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    },   
    dateEnd: function(val) {
      if (val) {
        this.listQuery.enddate = this.dateEnd
      } else {
        this.listQuery.enddate = undefined
      }
        this.listAll= [];
        this.listQuery.page = 0;
        this.resetPage(this.getList);     
      //this.debounceGetList(this.getListAll, 2000) // 監聽輸入框，一有變化就送請求
    },           
  },

  methods: {
    exportToCSV() {
      this.logOperate('report','/report/station','EXPORT','匯出充電站充電統計報表 csv');
      //const csvContent = "data:text/csv;charset=Big5," + this.convertToCSV(this.listXls); //utf-8 
      const csvContent = this.convertToCSV(this.listCsv); //utf-8 
      //const csvContent = new Blob('\ufeff${csv}', {type: 'text/plain;charset=utf-8'})+ this.convertToCSV(data);
      //const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      //link.setAttribute("href", encodedUri);
      //link.setAttribute("download", "data.csv");
      //document.body.appendChild(link);
      //link.click();

      // "\ufeff"是为了解决CSV中文
      let blob = new Blob(["\ufeff" +csvContent],{type: 'text/csv;charset=utf-8;'});
      let url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', this.filenameAllXlsx);
      link.click();
    },
    convertToCSV(data) {
      const columnDelimiter = ",";
      const lineDelimiter = "\n";
      const keys = Object.keys(data[0]);

      let result = "";
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      data.forEach(item => {
        let row = "";
        keys.forEach(key => {
          row += item[key] + columnDelimiter;
        });
        result += row.slice(0, -1);
        result += lineDelimiter;
      });

      return result;
    },     
    showPointName(row,column,cellValue) {
      console.log("this.$store.state.chargingPoints=",this.$store.state.chargingPoints)
      return (this.$store.state.chargingPoints || []).find((item) => item.id === cellValue).name;
    },
    getStationByServiceId() {
      console.log("GET:/api/v1/report/stationidListByserviceId?serviceId="+this.listQuery.serviceId);
      this.axios.get("/api/v1/report/stationidListByserviceId?serviceId="+this.listQuery.serviceId).then(
          res => {
          console.log("Response:/api/v1/report/stationidListByserviceId [res.data]",res.data);
          this.stationOption=res.data;
          }
         );
    },
    getList() {
      var number = this.page
      var size   = this.size

      if( (this.listQuery.startdate==null) || (this.listQuery.enddate==null) ) {
        this.listQuery.startdate = this.$moment().subtract(90, "days").format("YYYY-MM-DD")//90天前;
        this.listQuery.enddate   = this.$moment().format("YYYY-MM-DD")
      }

      this.listLoading = true
      console.table('GET:/api/v1/report/charging [params]:',this.listQuery);
      this.axios.get('/api/v1/report/charging', {params: this.listQuery}).then(res=> {
        console.log("Response:/api/v1/report/charging [res.data]:",res.data)
        this.list = res.data.content
        this.total = res.data.totalElements
        number = res.data.number
        size   = res.data.size

        /* 讓 this.list的Map加入一個key為no */
        this.list.map( ( item , index ) => {
            item.no = index + number*size +1
            return item
        })
        /* 讓 this.list的Map加入一個key為no */

        this.listLoading = false
      })


    },
    exportReport(){
      console.table('GET:/api/v1/report/chargingall [params]:',this.listQuery);
      this.axios.get('/api/v1/report/chargingall', {params: this.listQuery}).then(res=> {
        console.log("Response:/api/v1/report/charging [res.data]:",res.data)
        this.listAll = res.data

        var output = this.listAll.map( ( item , index ) => {
            item.no = index   //讓 this.list的Map加入一個key為no            
            return item
        })
        this.listAll = output;

        this.listCsv = this.listAll.map(( item , index ) => {
          //item.no = index + number*size +1
          if(this.$i18n.locale == 'tw'){
            return {
              '充電站'  :   item.stationname,              
              '充電樁'  :   item.name,
              '充電槍'  :   item.connector_id,
              '狀態'    :   item.state,
              '電壓(V)' :   item.voltage,
              '電流(A)' :   item.current_import,
              '功率(W)': item.power_active_import,
              '電表度數(Wh)':   item.energy_active_import_register,
              '區間度數(Wh)': item.energy_active_import_interval,
              '更新日期':   item.update_time              
            }
          } else {
            return {
              'Charging Station':   item.stationname,              
              'Charging Point':   item.name,
              'Charging Connector':   item.connector_id,
              'Status'    :   item.state,
              'Voltage(V)':   item.voltage,
              'Current(A)':   item.current_import,
              'Power(W)'  :   item.power_active_import,
              'Meter Degree(Wh)':   item.energy_active_import_register,
              'Interval Degree(Wh)': item.energy_active_import_interval,
              'Update Date':   item.update_time
            }          
          }
       
        })

      })
      this.filenameCsv =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_ChargingReport" + ".xls"
      this.filenameAll =    this.$moment().format("YYYYMMDD-HHmmss").toString() + "_ChargingReportAll" + ".xls"
      this.filenameAllXlsx= this.$moment().format("YYYYMMDD-HHmmss").toString() + "_ChargingReportAll"
    },
    /*
    getListAll() {
      this.axios.get('/api/v1/report/chargingall', {params: this.listQuery}).then(res=> {
        console.log("/api/v1/report/chargingall",res)
        this.listAll = res.data
      })
    }, */
    handleRowClick(row, column, event) {
    },
    /*
    objectSpanMethod({ row, column, rowIndex, columnIndex}) {
      if (columnIndex ===0) {
        if(rowIndex %2 === 0) {
          return { rowspan:2, colspan:1};
        } else {
          return { rowspan:0, colspan:0};
        }
      }
    }, */

  },
}

</script>
